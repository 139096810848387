/* @font-face {
  font-family: "SemplicitaPro";
  src: url(" ../src/assets/fonts/SemplicitaPro.otf");
} */
/* * {
  font-family: "semplicitapro-medium";
} */
* {
  font-family: "Font Awesome 5 Pro", sans-serif !important;
  /* font-weight: 600 !important; */
}

/* body,
html {
  margin: 0;
  overflow-x: hidden; */
/* overflow-y: hidden; */
/* height: 100%; */
/* } */
body {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* Style the scrollbar */
*::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 12px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 12px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar handle on hover */
  cursor: pointer;
}

.login-form .form-label,
.user-signup-form .form-label,
.org-signup-form .form-label,
.new-password .form-label {
  color: #0047bb;
  font-size: 14px;
  visibility: visible;
}
.login-form .form-control:focus + .form-label,
.user-signup-form input:focus + .form-label,
.org-signup-form .form-control:focus + .form-label {
  visibility: visible;
}

.login-form .form-control,
.user-signup-form .form-control,
.org-signup-form .form-control,
.forget-password .form-control,
.new-password .form-control {
  border-bottom: 2px solid #cbced4 !important;
  border: unset;
  border-radius: unset;
  padding-left: unset;
}
.login-form .form-control:focus,
.user-signup-form .form-control:focus,
.org-signup-form .form-control:focus,
.forget-password .form-control:focus,
.new-password .form-control:focus {
  box-shadow: unset;
  visibility: visible !important;
  border-bottom: 2px solid #0047bb !important;
}
.login-form .fa {
  float: right;
  position: relative;
  margin-top: -24px;
}
.new-password .card .card-body .fa,
.user-signup-form .fa {
  float: right;
  position: relative;
  margin-top: -40px;
}

.login .left-side {
  background-image: url("/public/img/bg.png");
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  background-blend-mode: overlay;
  padding-left: 80px;
}

.btn-login {
  padding: 0.5rem 2rem;
  border-radius: 5px;
  color: white;
  background-color: #0047bb;
  border: unset;
  font-size: x-large;
}
.btn-signup {
  padding: 0.5rem 2rem;
  border-radius: 5px;
  color: white;
  border: 2px solid white;
  background-color: transparent;
  font-size: x-large;
}
.signup .btn-register {
  display: block;
  width: 100%;
  font-size: x-large;
  background-color: #0047bb;
  border-radius: 8px;
}
.signup .btn-logo {
  padding: 0.5rem 14.3rem;
  font-size: large;
  background-color: #f2f2f7;
  border-radius: 8px;
  color: #00bfb2;
  border: 2px dashed #00bfb2;
}
/* signup */
.signup .left-side {
  background-image: url("/public/img/signup-user.png"),
    linear-gradient(rgba(180, 218, 75, 1), rgba(27, 70, 180, 1));
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  background-blend-mode: overlay;
  padding-left: 80px;
  background-blend-mode: soft-light;
}
.signup .nav-tabs {
  border-bottom: unset;
}
.signup .nav-tabs .nav-link {
  border: unset;
  border-bottom: 2px solid;
}
.signup .nav-tabs .nav-item button {
  font-weight: bold;
}
/* forgetpassword, new password */
.background-screen {
  background: linear-gradient(#56bcb2, #1b46b4);
  height: 100vh;
}
.forget-password .card,
.new-password .card,
.otp-password .card {
  border-radius: 14px;
}

/* .forget-password .card .card-body p {
  margin-left: 14px;
  width: 318px;
} */
.new-password .card .card-body p {
  margin-left: 80px;
  width: 324px;
}
.otp-password .card .card-body p {
  margin-left: 27px;
  width: 318px;
}
/* otp */
.otp-password .verify-input input {
  border: none;
  border-bottom: 2px solid #ced4da;
  width: 2em !important;
}
.otp-password .verify-input input:focus {
  border: none;
  border-bottom: 2px solid #1b46b4 !important;
  outline: unset;
}
.otp-password .verify-input .otpContainer {
  margin: 0 1rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
}

/* nav-top */
.nav-top form .form-control {
  border-radius: 20px;
  border: unset;
  background-color: #f2f2f4;
}
.searchBarMain {
  position: relative;
  cursor: pointer;
  margin: 0px;
}
/* sidebAR */
.sidebar-outer {
  display: table;
  height: 100%;
}
#sidebar-dashboard {
  padding: 8px;
}
.main-sidebar {
  display: table-cell;
  /* width: 320px; */
  padding: 18px 0 25px 24px;
  /* position: fixed; */
  height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(#56bcb2, #1b46b4);
}
.main-sidebar .sidebar-menu ul {
  list-style: none;
  padding: 0;
}
.main-sidebar .sidebar-menu ul li {
  margin-bottom: 7px;
}
.main-sidebar .sidebar-menu ul li a {
  color: white;
  display: flex;
  width: 226px;
  padding-left: 8px;
}
.main-sidebar .sidebar-menu ul li a:hover {
  color: #1b46b4;
  background-color: white;
  border-radius: 5px;
  width: 223px;
}
.main-sidebar .sidebar-menu ul li a:hover .menu-item-icon {
  color: white;
  background-color: #1b46b4;
  border-radius: 5px;
}
.main-sidebar .sidebar-menu ul li a .menu-item-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
}
.main-sidebar .sidebar-menu ul li a .menu-item-name {
  font-weight: 600;
  padding-left: 6px;
  padding-top: 2px;
}
.main-sidebar .sidebar-menu ul li .active {
  color: #1b46b4;
  background-color: white;
  border-radius: 5px;
  width: 217px;
  padding-left: 8px;
}
.main-sidebar .sidebar-menu ul li .active .menu-item-icon {
  color: white;
  background-color: #1b46b4;
  border-radius: 5px;
}

/* dashboard */
.dashboardProgressTitle {
  font-size: 17px;
}
.dashboardProgressDays {
  font-size: 13px;
}
.dashboard .goals .goal-img-rested {
  background-color: #383ca1;
  border-radius: 30px;
  height: 61px;
  width: 61px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
/* .dashboard-goals-container {
  height: 225px;
  overflow-y: auto;
} */
.dashboard .goals .goal-img-exc {
  background-color: #aadb1e;
  border-radius: 30px;
  height: 61px;
  width: 61px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.dashboard .goals .goal-img-quit {
  background-color: #00bfb2;
  border-radius: 30px;
  height: 61px;
  width: 61px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.dashboard .goals .progress .progress-bar {
  background: linear-gradient(to right, #b4da4b, #1b46b4);
}
.dashboard .card {
  border: unset;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}
.dashboard .activities span {
  color: #a6b1c4;
}
.goalDefaultImg {
  width: 60px;
}
#controlled-tab-example-tabpane-goals {
  max-height: 60vh;
  overflow-y: auto;
  min-height: 15vh;
}

/* app header */
.top-dashboard {
  /* box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4); */
}
.new-journey-card {
  height: 42vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  background-color: #f8f9fa; /* Light background */
}
.css-19kzrtu {
  margin-top: 2vh;
  padding: 0px !important;
}
.survey-button {
  background-color: #1b47b4 !important;
  border-color: #1b47b4;
  color: white;
}
.survey-card-container-heading {
  font-size: x-large;
  margin-top: 2%;
  font-weight: bolder;
}
.survey-cards-container {
  text-align: left;
  padding: 2%;
  border-radius: 20px;
  display: flex;
  border: 4px solid;
  margin: 2%;
  cursor: pointer;
}
.survey-cards-title {
  margin: auto;
  padding-left: 4%;
  width: 20vw;
}
/* .css-1qdzy9k-MuiBarElement-root {
  width: 40px !important;
} */
/* .survey-cards-container {
  text-align: left;
  padding: 2%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #dfe3e6; 
  margin: 2%;
  background-color: #ffffff; 
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); 
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease; 
}

.survey-cards-title {
  margin: auto;
  padding-left: 4%;
  font-weight: bolder;
  flex-grow: 1;
}

.status-icon {
  height: 30px;
  width: 30px;
}

.survey-cards-container:hover {
  transform: translateY(-8px); 
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); 
  border-color: #007bff; 
} */
.survey-button:hover {
  background-color: #6690f1 !important;
  border-color: #6690f1 !important;
}
.top-dashboard-username {
  font-size: 26px;
  font-weight: 600;
}
.btn-coach {
  color: white;
  background-color: #00bfb2 !important;
  border: unset !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  font-weight: 600 !important;
}
.top-dashboard .btn-srvc {
  background-color: #3174ad !important;
  /* background: none; */
  border: unset;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
}
.navbarbarSearchIcon {
  position: absolute;
  top: 8px;
  right: 10px;
}
.dashboard-downcards {
  padding: 3vh 30px 0px 30px;
}
.client-name-status {
  font-size: 12px;
  color: #8c99a8;
  text-align: left;
}

.journeys-heading {
  text-align: center;
  color: #1b46b4;
  margin-bottom: 20px;
  font-size: 24px;
}

.engagement-rate-heading {
  margin-bottom: 10px;
  font-size: 18px;
}
.daily-content-title {
  cursor: pointer;
  background: #1b47b4;
  margin: 11px 0px 5px 0px !important;
  border-radius: 9px;
  color: white;
  padding: 18px !important;
  height: 6vh;
  word-break: break-all;
  word-wrap: break-word;
  min-width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s, transform 0.3s, border-radius 0.3s;
}

.daily-content-title:hover {
  background-color: #3d5af1;
  transform: scale(1.05);
  border-radius: 12px;
}
.cards-header img {
  height: 35px;
}
.add-question-modal-body {
  max-height: 600px;
  overflow-y: auto;
}
/* survey */
.survey .card {
  border: unset;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}
.survey .card .nav-tabs .nav-link.active {
  border-bottom: 3px solid #0047bb;
  color: black;
}
.survey .card .nav-tabs .nav-link {
  border: unset;
  color: #a6b1c4;
  padding: 1rem 2rem;
}
.survey .main-content .question p {
  color: #0047bb;
}
.survey .main-content .question .btn-yes-no {
  border: unset;
  background-color: #a6b1c4;
  padding: 7px 30px;
  border-radius: 20px;
}
.survey .main-content .question .btn-yes {
  border: 2px solid #0047bb;
  background-color: white;
  padding: 7px 30px;
  border-radius: 20px;
}
.survey .tab-content .btn-send-question {
  border: 2px solid #0047bb;
  border-radius: 20px;
}

.survey .main-content .form-check-input[type="checkbox"] {
  border-radius: 1em;
  margin-top: 3px;
}
.survey .main-content .question .form-control {
  border-bottom: 2px solid #cbced4 !important;
  border: unset;
  border-radius: unset;
  padding-left: unset;
}
.survey .details .detail-list ul li::before {
  content: "•";
  color: #00bfb2;
  display: inline-block;
  width: 2em;
  font-size: x-large;
}
.details {
  max-height: 55vh;
  overflow-y: auto;
}
.detail-survey-user-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  /* margin-right: 5px; */
}
.detail-spinner {
  position: absolute;
  left: 434px;
}

#mulit-box {
  border-radius: 0em !important;
}
.survey .tab-content .goals .highGoal {
  margin: 0 auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #ec6666;
  padding: 5px 0;
}
.survey .tab-content .goals .avgGoal {
  margin: 0 auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #21b3ab;
  padding: 5px 0;
}
.survey .tab-content .goals .lowGoal {
  margin: 0 auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #a0cd32;
  padding: 5px 0;
}
.medium-risk-goals {
  margin-right: 32px;
}
.goals-priority-title {
  color: white;
  text-align: center;
}
.take-survey-tab {
  overflow-y: auto;
  height: 70vh;
}
.survey
  .tab-content
  .goals
  form
  .form-check
  .form-check-input[type="checkbox"] {
  background-color: #d9e0e8;
  border-radius: unset;
}
.survey .tab-content .goals form .form-check .form-check-input:checked {
  background-color: #1b46b4;
  border-radius: unset;
}
.survey .tab-content .btn-save-goal {
  color: white;
  background-color: #00bfb2;
  border: unset;
  padding: 10px 20px;
  border-radius: 5px;
}
.survey-question-input {
  margin-bottom: 20px;
  margin-left: 70px;
}
.question-input {
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  float: left;
}
.setGoalsFonts {
  font-weight: 600;
}
.lowRisksGoals {
  color: #1b46b4;
}
.container-survey-goals-riskfactors {
  background-color: white;
  margin: 10px 0px;
  border-radius: 15px;
  /* border: 1px solid gray; */
}

.title-survey-goals-riskfactors {
  text-align: center;
  font-size: 15px;
  padding-top: 3px;
  height: 31px;
  font-family: "SemplicitaPro-Medium", sans-serif;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  overflow: hidden;
  width: 112px;
  margin: 0 auto;
}
.container-survey-goals-checkboxoptions {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
}

.selectedText-survey-goals-checkboxoptions {
  color: black;
  font-family: "SemplicitaPro-Medium", sans-serif;
  font-size: 16px;
}

.unselectedText-survey-goals-checkboxoptions {
  color: gray;
  font-family: "SemplicitaPro-Medium", sans-serif;
  font-size: 16px;
}

.survey-goals-checkboxoptions {
  height: 23px;
  width: 23px;
  margin-right: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
}

.image-survey-goals-checkboxoptions {
  width: 23px;
  border: 1px solid;
  border-radius: 5px;
}
.container-goals {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .btn-save-goal {
  width: 90%;
  background-color: #00bfb2;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
*/
.btn-save-goal:hover {
  background-color: #019b8b;
}

/* health-kit */
.healthKitUpdateHeaderFont {
  font-size: 30px;
}
.healthKitUpdateCardsFont {
  margin: 5px 0px 0px 20px !important;
}
.health-kit {
  /* box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 40%); */
}
.health-kit .card {
  border: unset;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 10px;
}
.health-kit .card .progress .progress-bar {
  background: linear-gradient(to right, #b4da4b, #1b46b4);
}
.health-kit .weight .card .position-absolute {
  bottom: 20%;
  left: 44%;
}
.main-health-container {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 8%;
}
.sleep-duration {
  margin-bottom: 0px !important;
  margin-top: 10px;
}
.user-weight {
  margin-bottom: 16px;
}
.weight-if-no-data {
  height: 52px;
}
.Sleep-icon {
  width: 80px;
}
.Steps-icon {
  width: 90px;
}
.Bloodpressure-icon {
  width: 97px;
}
.weight-icon {
  width: 80px;
}
.Heart-rate-icon {
  width: 90px;
}
.modal-open {
  padding-right: 0px !important;
}
.card-container {
  cursor: pointer;
}
@keyframes scaleOpacity {
  0% {
    transform: scale(1);
    opacity: 1;
    background-color: rgb(27, 70, 180);
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
    background-color: white;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    background-color: rgb(27, 70, 180);
  }
}

.highlight {
  background-color: rgb(27, 70, 180);
  animation: scaleOpacity 1s ease;
}
/* chat coach */
.chat-coach {
  padding: 3%;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5% auto;
}
.clients-name-chatlist {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.chat-coach .card {
  border: unset;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}
.card-body-chat {
  max-height: 655px !important;
}
.sender-message-bubble {
  flex-direction: column;
}
.chat-coach .coach-profile .bio-detail {
  background-color: #f1f4f9;
}
.chat-coach .coach-profile .bio-detail .cross-img img {
  background-color: #d8dfe7;
  width: 25px;
  height: 25px;
  padding: 4px;
  border-radius: 13px;
}
.chat-coach .coach-profile .coach-email {
  color: #1b46b4;
}
.chat-coach .coach-profile .btn-bio button {
  border: unset;
}
.chat-conversation {
  height: 532px;
}
.chat-input-section {
  padding: 0px !important;
}
.scrollbar-chat-coach {
  height: 515px !important;
}
.chat-coach .chat-conversation .scrollbar {
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}
.chat-coach .chat-conversation .scrollbar .separator {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  padding: 0px 30px 0px 30px;
}
.chat-coach .chat-conversation .scrollbar .separator .line {
  height: 0.7px;
  flex: 1;
  background-color: #9c9fa3;
}
.chat-coach .chat-conversation .scrollbar .separator span {
  padding: 0 2rem;
}
.chat-coach .chat-conversation .scrollbar .conversation-list .media {
  width: fit-content;
}
.chat-coach .chat-conversation .scrollbar .conversation-list .sender-msg {
  background-color: #56bcb2;
  margin-right: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  max-width: 400px;
  text-align: justify;
}
.chat-coach .chat-conversation .scrollbar .conversation-list .rcvr-msg {
  background-color: #64748b;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  max-width: 400px;
}
.reciever-message-bubble {
  margin-left: 0px !important;
}
.message-time {
  font-size: 10px !important;
}
@media only screen and (max-width: 480px) {
  .login .left-side {
    padding-left: 26px;
  }
  .signup .left-side {
    padding-left: 26px;
  }
  .signup .btn-register {
    padding: 0.5rem 6.5rem;
  }
}
@media only screen and (min-width: 360px) {
}
/* Activity planner */
.modal-content {
  border-radius: 1rem;
  height: 600px px;
}
.ql-editor {
  min-height: 150px;
  max-height: 250px;
  overflow-y: auto;
}
.activity-popup-cross {
  width: 100%;
  padding-top: 20px;
  padding-right: 20px;
}
.activity-popup-cross img {
  float: right;
  cursor: pointer;
}
.event-popup {
  position: absolute;
}
.modal-body {
  background-color: #f1f4f9;
}
.modal-footer {
  border: none !important;
}
.activity-popup-header {
  padding: 23px;
}
.activity-checkbox {
  margin-right: 16px;
  margin-top: 11px;
}
.popup-search-bar {
  position: relative;
}
.send-btn-img {
  position: absolute;
  top: 20px;
  right: 45px;
  width: 19px;
  cursor: pointer;
}

.popup-inputfield {
  border-radius: 32px !important;
  line-height: 1.8;
  padding: 14px 50px 14px 14px !important;
  margin-left: 20px;
  width: 90% !important;
}
.comment-container {
  margin: 30px;
}
.comments-section-container {
  max-height: 250px;
  overflow-y: auto;
}

.comments-pic-container .profile-pic {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #e5dbdb;
}
.comments-section {
  margin-top: 10px;
}
.comments-title p {
  font-size: 18px;
  margin-bottom: 2px !important;
}
.comments-body {
  display: flex;
}
.comments-userinfo {
  margin-left: 5px;
  margin-top: 2px;
}
.comments-username {
  margin-bottom: 2px !important;
  margin-left: 3px;
}
.comments-user-activitytime {
  font-size: 12px !important;
  margin-left: 5px;
  color: #64748b;
}
.comments-text {
  color: #101c32;
  font-size: 14px;
  border-bottom: 1px solid #cdd4de;
  width: 70%;
  margin-left: 58px;
}
.comments-text p {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.event-info p {
  color: #64748b;
  font-size: 14px;
}
.event-info-and-button {
  display: flex;
  justify-content: space-between;
}
.event-date-time {
  color: #a6b1c4;
  font-size: 12px;
  margin-bottom: 2px;
}
.event-title {
  color: #101c32;
  font-size: 16px;
  margin-bottom: 2px;
}
/* .rbc-toolbar .rbc-toolbar-label {
  padding-right: 224px !important;
} */
/* round checkbox */
.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #0047bb;
  border-color: #0047bb;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

/* user profile */
.user-profile {
  /* box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 40%); */
}

.profile-main-container {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}
.profile-header {
  width: 100%;
  display: flex;
  margin-bottom: 40px;
}
.profile-header h1 {
  /* font-size: 15px; */
  float: left;
}
.profile-info {
  display: flex;
  justify-content: space-evenly;
}
.rbc-btn-group {
  display: none;
}
.profile-btn button {
  position: absolute;
  top: 20px;
  right: 22px;
  padding: 8px 30px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #21b3ab;
  border: none;
}
.profile-heading {
  color: #0047bb;
}
.user-email {
  display: flex;
}
.email-wrapper {
  display: flex;
  flex-direction: column;
}
.email-head {
  padding-right: 145px;
  margin-bottom: 2px !important;
  font-size: 13px;
  float: left;
}
.email-icon {
  margin: 9px 16px 0px 0px;
}
.user-phone {
  display: flex;
}
.phone-head {
  padding-right: 37px;
  margin-bottom: 2px !important;
  font-size: 13px;
  float: left;
}
.phone-icon {
  margin: 5px 16px 0px 0px;
}
.edit-profile-btns {
  display: flex;
}
/* edit profile inputs */

form {
  margin-right: 45px;
}
.profile-inputs {
  margin-bottom: 45px;
}
/* .edit-profile-inputs {
  margin: 0px 0px 0px 350px;
} */
.edit-profile-input-field {
  position: relative;
  width: 250px;
  height: 44px;
  line-height: 44px;
  margin-left: 60px;
}
.edit-profile-input-field label {
  position: absolute;
  top: 19px;
  left: -87px;
  width: 100%;
  color: #d3d3d3;
  transition: 0.2s all;
  cursor: text;
}
.edit-profile-input-field input {
  width: 135%;
  border: 0;
  outline: 0;
  padding: 0.5rem 0;
  border-bottom: 2px solid #d3d3d3;
  box-shadow: none;
  color: #111;
}
.edit-profile-input-field input:invalid {
  outline: 0;
}
.edit-profile-input-field input:focus,
.edit-profile-input-field input:valid {
  border-color: #0047bb;
}
.edit-profile-input-field input:focus ~ label,
.edit-profile-input-field input:valid ~ label {
  font-size: 14px;
  top: -23px;
  right: 90px;
  color: #0047bb;
}
/* edit profile buttons */
.edit-profile-btns {
  display: flex;
  margin-top: 30px;
}
.edit-profile-button-save {
  margin-left: 15px;
  padding: 10px 50px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #0047bb;
  border: none;
}
.edit-profile-button-cancel {
  margin-left: 120px;
  padding: 10px 30px;
  border-radius: 5px;
  color: #8c99a8;
  background-color: #d9e0e8;
  border: none;
}
.edit-profile-img-icon {
  margin-top: -355px;
  margin-left: 164px;
  cursor: pointer;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
  /* position: absolute; */
  top: -415px;
  left: 656px;
  /* top: 50px;
 
  transform: translate(656%, -415%); */
}
.loader-activity-modal-comments {
  margin-left: 210px;
}
.loader-activity-modal-info {
  margin-left: 155px;
}
.loader-activity {
  position: absolute;
  top: -415px;
  left: 656px;
}
.activity-main {
  position: relative;
}
.user-profile-img {
  border: 2px solid #e5dbdb;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-top: 60px;
  object-fit: cover;
}
.email-address {
  margin-right: 93px;
}
.image-file > input {
  display: none;
}
.phone-number {
  padding-right: 13px;
}

/*  subscription plans */
.ElementsApp.InputElement {
  color: #1b46b4 !important;
  background-color: #111;
}
.ElementsApp .InputElement.is-invalid {
  color: red !important;
}

/* notification */
.bell-on-navbar {
  cursor: pointer;
  position: relative;
  margin-right: 20px;
}
.star-on-navbar {
  cursor: pointer;
  position: relative;
  margin-right: 20px;
}
.counter-in-bell {
  position: absolute;
  background-color: red;
  top: 4px;
  left: 16px;
  color: white;
  border-radius: 50%;
  width: 24px;
}
.counter-in-bell-with-99 {
  position: absolute;
  background-color: red;
  top: 4px;
  left: 16px;
  color: white;
  border-radius: 32%;
  width: 30px;
}
.dropdown-menu {
  /* position: absolute; */
  /* display: none; */
  z-index: 1;
  /* // left: -110px; */
  left: -130px;
  top: 60px;
  min-height: 10px;
  min-width: 10px;
  /* // width: 230px; */
  width: 280px;
}

.notification-counter {
  color: white;
  background-color: red;
  width: 25px;
  border-radius: 5px;
  margin-left: 5px;
  text-align: center;
}
.dropdown-header {
  background: white;
  padding: 15px;
  position: relative;
  text-align: center;
  color: fg-gray;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  @include notification-border(1px);
  box-shadow: 0px 2px 10px -2px lighten(primary-blue, 15%);
}
.triangle {
  /* // position: absolute;
      // top: -10px;
      // left: 112px;
      // height: 0;
      // border-left: 12px solid transparent;
      // border-right: 12px solid transparent;
      // border-bottom: 12px solid $white; */
  position: absolute;
  top: -8px;
  left: 134px;
  height: 15px;
  width: 15px;
  border-radius: 6px 0px 0px 0px;
  transform: rotate(45deg);
  background: white;
}
.count {
  position: static;
  height: 25px;
  width: 25px;
  display: inline-block;
  line-height: 24px;
  margin-left: 8px;
  font-size: 12px;
  vertical-align: middle;
}
.notification-container {
  border: 1px solid black;
  width: 352px;
  position: absolute;
  right: 300px;
  top: 70px;
  background-color: white;
  border-radius: 10px;
  z-index: 10;
}
.notification-header {
  padding-top: 13px;
}
.navbar-main-container {
  position: relative;
}
.user-img-navbar {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 5px;
}
.user-img-navbar .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /*   
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 5px; */
}
.bonus-body {
  padding: 13px;
  border-radius: 8px;
  height: 100px;
  font-size: 17px;
  color: #1b46b4;
  font-weight: bold;
}
.notification-body {
  padding: 13px;
  border-radius: 8px;
  max-height: 360px;
  min-height: 160px;
  overflow-y: auto;
}
.notification-unseen {
  border: 1px solid #00bfb230;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 5px 0px 10px 0px;
  background-color: #dfdfdf;
}
.notification-unseen :hover {
  cursor: pointer;
}
.notification-userData {
  margin-bottom: 10px;
  padding: 5px 0px 10px 0px;
}
.notification-userData :hover {
  cursor: pointer;
}
.notification-name-time {
  display: flex;
  justify-content: space-between;
}
.notification-userName {
  text-align: initial;
  padding-left: 10px;
}
.notification-userMsg {
  text-align: initial;
  font-size: 13px;
  padding-left: 10px;
  color: #847e7e;
  word-break: break-word;
}
.notification-time {
  color: #96a1b4;
  font-size: 11px;
  padding-right: 10px;
}

.notification-page {
  /* box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 40%); */
  padding: 3%;
}
.notification-page-header {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}
.notification-page-main-container {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 8px;
  max-height: 70vh;
  overflow-y: auto;
}
.notification-page h1 {
  float: left;
  font-size: 2.5rem;
  /* font-weight: 800 !important; */
}

.notification-page-userData {
  border-bottom: 1px solid #00bfb230;
  padding: 30px 35px 30px 20px;
}
.notification-page-userData :hover {
  cursor: pointer;
}
.notification-page-unseen {
  border-bottom: 1px solid #00bfb230;
  padding: 30px 35px 30px 20px;
  background-color: #dfdfdf;
}
.notification-page-unseen :hover {
  cursor: pointer;
}

.notification-page-name-time {
  display: flex;
  justify-content: space-between;
}
.notification-page-userName {
  text-align: initial;
  padding-left: 10px;
  display: flex;
}
.notification-page-userMsg {
  text-align: initial;
  font-size: 13px;
  padding-left: 33px;
  padding-top: 10px;
  color: #847e7e;
}
.notification-page-time {
  color: #847e7e;
  font-size: 11px;
  padding-right: 10px;
}
.notification-page-cross-btn {
  padding-right: 22px;
  padding-top: 3px;
}

/* ----------------------coach---------------------- */
.main-container-right {
  padding: 9% 50px 0px 50px;
  /* height: 100vh; */
}
.main-container-center {
  background-color: rgb(242, 242, 244);
  padding: 5% 5% 0 5%;
  height: 100vh;
}
.main-container-left {
  padding: 10% 50px 0px 50px;
  /* height: 100vh; */
}
.day-date-leftpanel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #d9e0e8;
}
.day-leftpanel {
  color: #8c99a8;
}

.date-leftpanel {
  font-size: 26px;
  margin-bottom: 30px;
}
.updates-selector {
  display: flex;
}
.updates-selector p {
  padding: 5px 5px 0px 5px;
}

.btn-update-list {
  color: black !important;
  background: none !important;
  border: none !important;
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}
.coach-dashboard-container {
  /* box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4); */
  --bs-gutter-x: 0rem !important;
}
/* .card-body {
  max-height: 290px;
  overflow-y: auto;
} */
.otp-card-body {
  max-height: 325px !important;
}
.engagement-rate-container {
  border-bottom: 1px solid #d9e0e8;
}
.engagement-rate-data {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}
.engagement-rate-data p {
  font-size: 30px;
  color: #1b46b4;
}

.journeys-heading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}
.Journeys-dates-container {
  border-bottom: 1px solid #d9e0e8;
}
.Journeys-dates-data {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}
.Journeys-dates-data p {
  font-size: 20px;
  color: #1b46b4;
}
.Journeys-dates-container h6 {
  display: flex;
  margin-bottom: 30px;
}
.Journeys-dates-param {
  display: flex;
  justify-content: space-evenly;
}
.Journeys-dates-param p {
  margin-bottom: 0px !important;
  color: #847e7e;
}

.due-soon-container {
  border-bottom: 1px solid #d9e0e8;
  padding-bottom: 30px;
  height: 30vh;
}
.due-soon-dropdown {
  display: flex;
  margin-top: 25px;
}
.today-activity-container {
  margin-top: 25px;
  display: flex;
}
.dropdownAtEnd ::after {
  margin-left: 1.255em !important;
}
.dropAtStart ::after {
  margin-left: -134px !important;
}
.needs-attention-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 25px;
}
.need-attention-data-list {
  overflow-y: auto;
  max-height: 28vh;
  width: 100%;
}
.client-sevendays-engagement {
  font-size: 12px;
}
.updates-from-client-container {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  margin-bottom: 30px;
}
.u-f-c-header {
  border-bottom: 1px solid #d9e0e8;
}
.client-img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #e5dbdb;
  margin-top: 1px;
}
.client-name {
  padding-left: 16px;
}
.client-img-name {
  padding: 24px 24px 13px 27px;
}
.client-name h5 {
  font-size: 17px;
  padding-right: 178px;
  float: left;
}
.client-activity-time {
  font-size: 12px;
  color: #8c99a8;
  padding-left: 16px;
}
.u-f-c-body {
  padding: 10px;
  border-bottom: 1px solid #d9e0e8;
}

.u-f-c-footer {
  padding: 20px;
}
.u-f-c-footer p {
  display: flex;
}
.u-f-c-comments-input {
  display: flex;
  margin-bottom: 15px;
}
.u-f-c-comments-input input {
  border: none;
  outline: none;
  width: 100%;
}
.emoji-camera .camera {
  margin-right: 18px;
  cursor: pointer;
}
.emoji-camera-and-button {
  display: flex;
  justify-content: flex-end;
}
.u-f-c-comments-sender-btn {
  margin-right: 11px;
}
.u-f-c-comments-sender-btn button {
  padding: 5px 12px;
  background: #56bcb2;
  border: none;
  color: white;
  border-radius: 5px;
}
.u-f-c-client-comment {
  margin: 20px 60px;
  padding: 10px;
  background-color: rgb(242, 242, 244);
  border: none;
  border-radius: 5px;
  border-bottom: 3px solid #00bfb2;
}
.u-f-c-client-comment .name-and-time {
  display: flex;
}
.name-and-time .name {
  margin-right: 10px;
}
.comments {
  word-break: break-all;
  padding-top: 5px;
  padding-left: 0px !important;
  font-size: 13px;
  float: left;
}
/* .comments p {

} */
.name-and-time .time {
  font-size: 12px;
  color: #8c99a8;
  margin-top: 5px;
}
.u-f-c-client-comment {
  margin-top: 10px;
  height: 78px;
}
.coach-nav-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.events img {
  padding-left: 15px;
  padding-top: 5px;
  width: 50px;
  height: 40px;
}
.events p {
  padding-left: 15px;
  padding-top: 7px;
}
.events-and-checkbox {
  display: flex;
  justify-content: space-between;
}
.u-f-c-client-comment comments {
  width: 100%;
  text-align: initial;
}
/* coach-clients */
.coach-clients-page {
  /* box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 40%); */
}
.clients-page-main-container {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 8px;
  padding: 0px 14px 14px 14px;
  max-height: 75vh;
  overflow-y: auto;
  display: block;
  border-top: 13px solid #ffffff;
}
.container-header {
  padding: 20px 30px;

  border: none;
}

.header-headings {
  display: flex;
  justify-content: space-around;
}
.header-headings h5 {
  font-size: 15px;
}
.clients-data-all {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e3e9ef;
}
.clients-data {
  padding-right: 80px;
}
.clients-data-all h5 {
  font-size: 13px;
}
.clients-img .img {
  padding-bottom: 10px;
}
.clients-name {
}
.duesoon-days {
  padding-right: 20px;
}
.engagements-rates {
  padding-left: 30px;
}
.engagements-rates h5 {
  color: #1b46b4;
}
.form-check .client-checkbox {
  /* width: 25px;
  height: 25px; */
  display: none;
}

table {
  border-collapse: collapse;
  width: 100%;
  padding: 20px !important;
}

/* tr:first-child td:first-child { border-top-left-radius: 100px; } */
td,
th {
  border: 1px solid #dddddd;
  text-align: left !important;
  padding: 8px;
}
th {
  position: sticky;
  top: 0;
  background-color: #f1f5f9;
  z-index: 2;
}
.header-row {
  background-color: #f1f5f9;
  padding: 15px 0px 8px 0px;
  /* border: 16px solid white; */
}
.pic-name-in-table {
  display: flex;
  justify-content: flex-start;
  margin-left: -65px;
}
.client-img-in-table {
  padding-right: 17px;
}
.whole-row-client:hover {
  background-color: #f1f5f9;
  cursor: pointer;
}

/* coach-notification */
.coach-notification-page {
  box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 40%);
}
.no-data-case {
  font-size: 20px;
  margin: 9%;
}
.coach-clients-data-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #e5dbdb;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn {
  box-shadow: none !important;
}
.activity-news-feed-container {
  max-height: 80vh;
  overflow-y: auto;
}
/* coach activities */
/* .coach-activities-page{
  box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 40%);
} */
#toggle-btn-clients {
  color: #fff !important;
  background-color: #fff !important;
  border-color: #fff !important;
  padding: 0px;
  width: 0px;
  border: 0px;
}
.dropdown-options {
  text-decoration: none !important;
  color: #111 !important;
}
.dropdown-options-container {
  width: 0px !important;
}
.create-activity-font {
  color: #111 !important;
  text-decoration: none !important;
}
.chat-and-event-btn {
  /* box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 40%); */
  width: 100%;
}
.add-event-btn {
  display: flex;
  justify-content: space-between;
  margin: 2% 2% 0 2%;
}
.add-event-btn button {
  background-color: #3174ad !important;
  border: unset;
  padding: 10px 20px;
  border-radius: 5px;
}
.activity-journey-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.activity-journey-dates {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.chat-client-btn button {
  color: white;
  /* background-color: #00bfb2; */
  border: unset;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 45px 15px 0px 0px;
  float: right;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 35px 10px 25px !important;
}
.modal-header-workout {
  background: #1b46b4;
  color: #ffffff;
}
.modal-header-workout .btn-close {
  color: #ffffff !important;
}
.react-date-picker__calendar-button__icon {
  display: none !important;
  padding: none !important;
}
.calendar {
  max-width: 100% !important;
}

.react-date-picker__clear-button__icon {
  margin-left: 45px;
}
.user-layout {
  width: 80% !important;
  box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 40%);
}
.coach-layout {
  width: 80% !important;
  box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 40%);
}
.react-date-picker__wrapper {
  background-color: #ffffff;
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem;
  padding: 5px;
}
.react-date-picker__button {
  padding: none !important;
}
.goalSelectorField {
  background-color: #ffffff;
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem !important;
  padding: 5px;
  max-width: 380px;
}
.goalSelectorFieldForm {
  margin: -2px;
}
/* Chat Client */
.chat-client-header {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}
.chat-client-username img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #e5dbdb;
}
.client-chat-img {
  border: 2px solid #e5dbdb;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}
.description-in-the-activity {
  margin-top: 20px;
  max-height: 250px;
  overflow-y: auto;
}
.today-activity-data {
  display: flex;
  flex-direction: row;
  padding-top: 13px;
}
.today-activity-data .client-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.today-activity-data-list {
  overflow-y: auto;
  max-height: 65vh;
}
.due-soon-data {
  overflow-y: auto;
  max-height: 25vh;
}
.due-soon-data-container {
  display: flex;
  flex-direction: row;
  padding-top: 13px;
}
.client-name-and-time {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15px;
}
.client-name-and-time .client-name {
  padding: 0px !important;
}
.client-time {
  font-size: 11px;
}
.chat-coach-username .image {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #e5dbdb;
}
/* .coach-profile .image {
  border-radius: 50%;
  width: 230px;
  border: 2px solid #e5dbdb;
} */
.coach-image {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #e5dbdb;
}
.client-name-and-date-news-feed {
  display: flex;
  flex-direction: column;
}
.spinner-of-coach-activities {
  position: absolute;
}
/* subscription */
.subs-main-container {
  border: unset;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  max-height: 670px;
  overflow-y: auto;
}

.goals-for-subscription {
  width: 30%;
}

.subscription-boxes {
  border: 1px solid black;
  padding: 12px 40px 12px 40px;
  border-radius: 18px;
  margin: 16px;
  border: unset;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}
.resources-main-container {
  display: flex;
  flex-wrap: wrap;
}
.subscription-boxes h5 {
  word-break: break-all;
  word-wrap: break-word;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.subscription-boxes p {
  color: red;
}
.subscriptionBackBtn {
  width: 100%;
}
.subscriptionBackBtn button {
  border-radius: 5px;
  color: #ffffff;
  background-color: #21b3ab;
  border: none;
  padding: 9px 15px;
  margin-left: 35px;
  margin-top: -49px;
  float: left;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
}
.small-size-sidebar-outer {
  display: table;
  height: 100%;
  width: 100%;
}
#sidebar-dashboard {
  padding: 8px;
}

.small-size-main-sidebar {
  display: table-cell;
  /* width: 320px; */
  padding: 15px 0 25px 10px;
  /* position: fixed; */
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(#56bcb2, #1b46b4);
}
.small-size-main-sidebar .sidebar-menu ul {
  list-style: none;
  padding: 0;
}
.small-size-main-sidebar .sidebar-menu ul li {
  margin-bottom: 7px;
}
.small-size-main-sidebar .sidebar-menu ul li a {
  color: white;
  display: flex;
  width: 226px;
  padding-left: 8px;
}
.small-size-main-sidebar .sidebar-menu ul li a:hover {
  color: #1b46b4;
  background-color: white;
  border-radius: 5px;
  width: 100%;
}
.small-size-main-sidebar .sidebar-menu ul li a:hover .menu-item-icon {
  color: white;
  background-color: #1b46b4;
  border-radius: 5px;
  padding-left: 6px !important;
}
.small-size-main-sidebar .sidebar-menu ul li a .menu-item-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
}
.small-size-main-sidebar .sidebar-menu ul li a .menu-item-name {
  font-weight: 600;
  padding-left: 14px;
  padding-top: 2px;
}
.small-size-main-sidebar .sidebar-menu ul li .active {
  color: #1b46b4;
  background-color: white;
  border-radius: 5px;
  width: 100%;
  padding-left: 8px;
}
.small-size-main-sidebar .sidebar-menu ul li .active .menu-item-icon {
  color: white;
  background-color: #1b46b4;
  border-radius: 5px;
}
.small-size-main-sidebar .sidebar-menu ul li .active .menu-item-icon img {
  padding-left: 5px;
}
.offcanvas {
  padding: none;
  width: 30% !important;
}
.offcanvas-body {
  padding: 0px !important;
}
.main-container-sidebar-btn {
  display: none;
}
.engagement-rate-data p {
  font-size: 25px;
  color: #1b46b4;
}
.engagement-rate-container h6 {
  display: flex;
  margin-bottom: 30px;
}
.engagement-rate-param {
  display: flex;
  justify-content: space-evenly;
}
.engagement-rate-param p {
  margin-bottom: 0px !important;
  color: #847e7e;
}
.resource-detail-body {
  border-right: 3px solid grey;
}
.resource-detail-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.resource-name {
  margin-left: 35px;
}
.price-and-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 37px;
  margin-top: 30px;
}
.terms-and-conditions-navbar {
  padding: 10px;
  height: 90px;
}
.terms-and-conditions-navbar img {
  float: left;
}
.terms-and-conditions-container {
  box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 40%);
  padding-top: 30px;
}
.terms-and-conditions-container h1 {
  float: left;
  /* margin-left: 30px; */
}
.terms {
  margin: 0px 50px 0px 50px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 0% 2% 5% 2%;
}
.terms p,
h4,
h2 {
  text-align: left;
}
.eqwell-store-main-container {
  text-align: center;
  width: 97%;
  margin: 10px;
  padding: 20px;
  border: unset;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}
/* MEDIA QUERIES */
/* @media only screen and (max-width: 992px) { */
@media only screen and (max-width: 1390px) {
  .main-sidebar {
    display: none;
  }
  .progress-bar-and-goal {
    margin-left: 12px;
  }
  .progress {
    margin-left: 10px;
  }
  .content-name {
    font-size: 15px;
    margin-left: 5px !important;
  }
  .main-container-sidebar-btn {
    display: block;
  }
  .edit-profile-btns {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
    margin-right: 50px;
  }
}
@media only screen and (max-width: 1150px) {
  .progress-bar-and-goal {
    margin-left: 12px;
  }
  .progress {
    margin-left: 10px;
  }
  .content-name {
    font-size: 15px;
    margin-left: 5px !important;
  }
  .coach-email {
    font-size: small;
  }
  .client-chat-img {
    border: 2px solid #e5dbdb;
    width: 144px;
    height: 144px;
    border-radius: 50%;
    object-fit: cover;
  }
  .main-container-right {
    padding: 20px;
    height: 100vh;
  }
  .main-container-center {
    padding: 20px;
    height: 100vh;
  }
  .main-container-left {
    padding: 20px;
    height: 100vh;
  }
  .engagement-rate-data p {
    font-size: 19px;
    color: #1b46b4;
  }
  .login-title h2 {
    font-size: larger;
  }
  .login-inputs {
    margin: 0px;
  }
}
@media only screen and (max-width: 1227px) {
  .cards-header h5 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1405px) {
  .engagement-rate-param p {
    font-size: small;
  }
  .client-sevendays-engagement {
    font-size: 8px;
    text-align: left;
  }
  .client-sevendays-engagement span {
    font-size: 8px;
  }
}
@media only screen and (max-width: 1250px) {
  .profile-inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .edit-profile-input-field {
    margin-bottom: 40px;
  }
  .profile-card-container {
    height: 500px;
    overflow-y: auto;
  }
  .user-profile-img {
    margin-top: 0px;
  }
  .client-name-status {
    font-size: 8px;
  }
  .today-activity-performers-client-name {
    font-size: 11px;
  }
  .due-soon-client-name {
    font-size: 11px;
  }
  .client-time {
    font-size: 8px;
  }
  .need-attention-client-name {
    font-size: 11px;
  }
  .client-activity-time {
    font-size: 8px;
    color: #8c99a8;
  }
  .client-name h5 {
    font-size: 10px;
  }
  .need-attention-data-list {
    overflow-y: auto;
    max-height: 38vh;
    width: 100%;
  }

  .today-activity-data-list {
    overflow-y: auto;
    max-height: 72vh;
  }
  .events p {
    padding-left: 15px;
    padding-top: 7px;
    font-size: 10px;
  }
  .coach-dashboard-activity-status-button {
    font-size: 9px !important;
  }
  .u-f-c-footer p {
    font-size: 9px;
  }
  .u-f-c-comments-input {
    font-size: 9px;
  }
}
/* @media only screen and (min-width: 700px) {
  .new-password-card-body {
   margin: 0px 0px 0px 0px !important;
  }
 
 } */

@media only screen and (max-width: 700px) {
  .new-password-card-body {
    margin: 0px !important;
  }
  .new-password-card-body p {
    font-size: smaller !important;
    margin: 0px 0px 30px 0px !important;
  }
  .new-password-form {
    margin: 0px !important;
  }
  .new-password-send-btn button {
    font-size: medium;
  }
}
@media only screen and (max-width: 280px) {
  .new-password-card-body p {
    width: 247px !important;
  }
}
@media (min-width: 740px) and (max-width: 800px) and (min-height: 1000px) and (max-height: 1050px) {
  .activity-news-feed-container {
    max-height: 84vh;
    overflow-y: auto;
  }
}
@media (min-width: 800px) and (max-width: 840px) and (min-height: 1160px) and (max-height: 1200px) {
  .need-attention-data-list {
    overflow-y: auto;
    max-height: 43vh;
    width: 100%;
  }
  .today-activity-data-list {
    overflow-y: auto;
    max-height: 75vh;
  }
  .activity-news-feed-container {
    max-height: 86vh;
    overflow-y: auto;
  }
}
@media (min-width: 980px) and (max-width: 1060px) and (min-height: 1300px) and (max-height: 1420px) {
  /* CSS rules here will be applied when the viewport is within the specified range */
  .need-attention-data-list {
    overflow-y: auto;
    max-height: 45vh;
    width: 100%;
  }
  .today-activity-data-list {
    overflow-y: auto;
    max-height: 80vh;
  }
  .activity-news-feed-container {
    max-height: 88vh;
    overflow-y: auto;
  }
}

/* Stripe  */
.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.StripeElement--invalid {
  border-color: #fa755a;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
